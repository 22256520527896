








































































































































import { type PropType, computed, defineComponent, reactive, watch } from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { z } from "zod";
import Icon from "@/components/elements/Icon.vue";
import { coreApi } from "@/lib/backend";
import type { AppointmentSlotViewModel } from "@/lib/backend/core.api";
import { DAYS, DAYS_LABEL } from "@/lib/backend/helpers";
import { buttonVariants } from "@/lib/pfg/components/button";
import { labelVariants } from "@/lib/pfg/components/label";
import { useGyms } from "@/lib/query/hooks/useGyms";
import { logger } from "@/logger";
import { useRoosterStore } from "@/pinia/rooster";

export default defineComponent({
	name: "OverlayCoachMomentUpdate",
	components: {
		Icon,
	},
	props: {
		data: {
			type: Object as PropType<AppointmentSlotViewModel>,
			default: () => ({}),
		},
	},
	emits: ["close"],
	setup(props, { emit }) {
		const queryClient = useQueryClient();
		const roosterStore = useRoosterStore();
		const { data: gyms } = useGyms();

		const slot = reactive<
			Pick<AppointmentSlotViewModel, "day" | "time" | "duration" | "gymId" | "itemType" | "roomId">
		>({
			day: props.data.day,
			time: props.data.time.slice(0, 5),
			duration: props.data.duration,
			gymId: props.data.gymId,
			itemType: props.data.itemType,
			roomId: props.data.roomId,
		});

		watch(
			slot,
			(value) => {
				value.roomId =
					gyms.value
						?.find((gym) => gym.id === value.gymId)
						?.zalen?.find((room) => room.naam === "Fitness zaal")?.id || 0;
			},
			{ deep: true },
		);

		const gym = computed(() => gyms.value?.find((gym) => gym.id === slot.gymId));
		const rooms = computed(() => gym.value?.zalen ?? []);

		const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
			mutationKey: ["roosters", props.data.gymId, "update"] as const,
			mutationFn: async (
				variables: Parameters<typeof coreApi.appointmentSlots.appointmentSlotsControllerUpdate>[1],
			) =>
				await coreApi.appointmentSlots
					.appointmentSlotsControllerUpdate(props.data.id, variables)
					.then((response) => response.data),
		});

		async function submit() {
			try {
				await mutateAsync({
					...slot,
					gymId: z.number().parse(slot.gymId),
					roomId: z.number().parse(slot.roomId),
					// @ts-expect-error `itemType` is not narrowed enough
					itemType: slot.itemType || "live",
					time: `${slot.time}:00`,
				});

				queryClient.refetchQueries({ queryKey: ["roosters", "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, "all"] });
				if (props.data.gymId !== slot.gymId) {
					queryClient.refetchQueries({
						queryKey: ["roosters", roosterStore.id, props.data.gymId],
					});
				}
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, slot.gymId] });

				emit("close");
			} catch (error) {
				logger.error("Failed to update coach moment", error);
			}
		}

		return {
			buttonVariants,
			labelVariants,
			DAYS,
			DAYS_LABEL,
			props,
			gyms,
			gym,
			rooms,
			slot,
			submit,
			isSuccess,
			isLoading,
			isError,
			error,
		};
	},
});
