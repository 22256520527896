import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LoginUsernamePassword {
	username?: string;
	password?: string;
}

export interface LoginRefresh {
	refresh_token?: string;
}

export interface OauthToken {
	token_type?: string;
	access_token?: string;
	expires_in?: number;
	refresh_token?: string;
	/**
	 * Afhankelijk van de gebruiker 1 of meerdere scopes. Mogelijke opties zijn `lid`, `trainer` en
	 * `club_aanvoerder`.
	 */
	scope?: string;
}

export interface OauthError {
	error?: string;
	error_description?: string;
}

export interface DatumRange {
	/** @format date-time */
	startDatum?: string;
	/** @format date-time */
	eindDatum?: string;
}

export interface AanwezigheidsLijstExtern {
	lesId: number;
	lijst: AanwezigheidExtern[];
}

export interface AanwezigheidExtern {
	/**
	 * @example
	 * 	1;
	 *
	 * @format number
	 */
	lidId: number;
	/**
	 * @example
	 * 	Jan;
	 */
	lidNaam?: string;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
	herhalend?: boolean;
	deelnames?: number;
}

export interface FitnessAfspraakExtern {
	/** @format int64 */
	id: number;
	/** @format int64 */
	roosterItemId: number;
	/** @format int64 */
	vestigingId: number;
	/** @format int64 */
	trainerId?: number;
	week: number;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
	/** @format date-time */
	datum: string;
	duur?: number;
	ingediend: boolean;
	aanwezigheid: AanwezigheidFitnessAfspraakExtern;
}

export interface AanwezigheidFitnessAfspraakExtern {
	/** @format int64 */
	lidId: number;
	/**
	 * @example
	 * 	Jan;
	 */
	lidNaam?: string;
	status: "aangemeld" | "afgemeld" | "geweest" | "niet_geweest";
	meting: boolean;
	trainingsSchema: boolean;
	doelOpstellen: boolean;
	personalTraining: boolean;
	kickOff: boolean;
	opmerkingen?: string;
	isTemporaryMember: boolean;
}

export interface GroepslessenResultaat {
	tracking?: 0 | 1;
	/**
	 * Naam van de laatst gevolgde groepsles
	 *
	 * @example
	 * 	RPM;
	 */
	lastLesson?: string;
	/**
	 * @example
	 * 	01-05-2017
	 *
	 * @format date
	 */
	lastLessonDate?: string;
	lastLessons?: {
		name?: string;
		/** @format date */
		datum?: string;
		amount?: number;
	}[];
	suggestions?: {
		/** Id van de groepsles */
		id?: number;
		/**
		 * @example
		 * 	BBB;
		 */
		name?: string;
		/**
		 * @example
		 * 	Lorem ipsum...
		 */
		description?: string;
		/**
		 * @example
		 * 	https://youtube.com/
		 */
		video?: string;
		/**
		 * @example
		 * 	https://profitgym.nl/afbeeling.jpg
		 */
		backgroundImage?: string;
	}[];
}

export interface GroepslesResultaat {
	id?: number;
	naam?: string;
	beschrijving?: string;
	groepslesTypes?: string[];
	afbeelding?: {
		formaat?: string;
		url?: string;
	}[];
	video?: string;
	attributen?: {
		attribuut?: string;
		waarde?: number;
	}[];
	kenmerken?: {
		attribuut?: string;
		waarde?: number;
	}[];
}

export interface AnnuleerData {
	/** Id van de te annuleren les */
	id: number;
	/** Optionele reden waarom de les geannuleerd wordt */
	reden?: string;
}

export interface LesUitgebreid {
	/**
	 * @example
	 * 	1;
	 */
	les_id: number;
	plekken: number;
	/**
	 * @example
	 * 	8;
	 */
	bezet: number;
	/**
	 * @example
	 * 	20:00
	 *
	 * @format time
	 */
	tijd: string;
	/**
	 * @example
	 * 	868;
	 */
	groepsles_id: number;
	/**
	 * @example
	 * 	BodyCombat;
	 */
	groepsles_naam: string;
	/**
	 * @example
	 * 	Ali Baba
	 */
	trainer_naam: string;
	/**
	 * @example
	 * 	3376;
	 */
	trainer_id: number;
	/**
	 * @example
	 * 	Zwolle Zuid
	 */
	vestiging_naam: string;
	/**
	 * @example
	 * 	Grote Zaal
	 */
	zaal_naam: string;
	status: "aankomend" | "afgelopen" | "geannuleerd";
	/**
	 * @example
	 * 	01-01-2017
	 *
	 * @format date
	 */
	datum: string;
	/**
	 * @example
	 * 	true;
	 */
	ingediend: boolean;
}

export interface Afspraak {
	/** @format number */
	afspraakId?: number;
	/**
	 * @example
	 * 	01-02-2017
	 *
	 * @format date
	 */
	datum?: string;
	/** @format time */
	slot?: string;
	busy: boolean;
	status?: "aankomend" | "afgelopen" | "geannuleerd";
	ingediend?: boolean;
}

export type AfsprakenMaand = Record<string, Afspraak[]>;

export interface TrainerResultaat {
	id?: number;
	naam?: string;
	groepslessenIds?: number[];
	vestigingenIds?: number[];
	foto?: string;
}

export interface IngelogdeGebruiker {
	id?: number;
	naam?: string;
}

export interface Intake {
	voornaam?: string;
	achternaam?: string;
	geslacht?: "man" | "vrouw";
	/**
	 * @example
	 * 	20-01-2018
	 *
	 * @format date
	 */
	geboortedatum?: string;
	adres?: string;
	postcode?: string;
	plaats?: string;
	telefoon?: string;
	email?: string;
	items?: MedicalItem[];
	medischCheck?: boolean;
	interesses?: ("Zonnebank" | "Afvallen in 12 weken" | "Sportvasten SwitchMe")[];
}

export interface MedicalItem {
	slug?:
		| "hartklachten"
		| "kortademigheid"
		| "longklachten"
		| "fysiotherapeut"
		| "overige"
		| "oefeningen"
		| "spierblessures"
		| "gewrichtsaandoening";
	enabled?: boolean;
	extraText?: string;
	selectables?: {
		text?: string;
		active?: boolean;
	}[];
}

export interface Welkom {
	voornaam?: string;
	achternaam?: string;
	email?: string;
	telefoon?: string;
	doel?: string;
	bereiken?: string;
	frequentie?: string;
	verwijzing?: string;
	interesses?: ("Zonnebank" | "Afvallen in 12 weken" | "Sportvasten SwitchMe")[];
}

export interface Tijdslot {
	/**
	 * @example
	 * 	1;
	 */
	tijdslot_id?: number;
	plekken?: number;
	/**
	 * @example
	 * 	8;
	 */
	bezet?: number;
	/**
	 * @example
	 * 	20:00
	 *
	 * @format time
	 */
	tijd?: string;
	/**
	 * @example
	 * 	868;
	 */
	groepsles_id?: number;
	/**
	 * @example
	 * 	BodyCombat;
	 */
	groepsles_naam?: string;
	/**
	 * @example
	 * 	Zwolle Zuid
	 */
	vestiging_naam?: string;
	status?: "aankomend" | "afgelopen" | "geannuleerd";
	/**
	 * @example
	 * 	01-01-2017
	 *
	 * @format date
	 */
	datum?: string;
	ingediend?: boolean;
}

export interface GuessedReservering {
	/**
	 * @example
	 * 	1;
	 */
	les_id: number;
	/**
	 * @example
	 * 	BodyCombat;
	 */
	groepsles_naam: string;
	/**
	 * @example
	 * 	Ali Baba
	 */
	trainer_naam: string;
	/**
	 * @example
	 * 	Zwolle Zuid
	 */
	vestiging_naam: string;
	/**
	 * @example
	 * 	01-01-2017
	 *
	 * @format date
	 */
	datum: string;
	/**
	 * @example
	 * 	20:00
	 *
	 * @format time
	 */
	tijd: string;
	/**
	 * @example
	 * 	Ridderzaal;
	 */
	zaal?: string;
	plekken?: number;
	bezet?: number;
	/**
	 * @example
	 * 	false;
	 */
	herhalend?: boolean;
}

export interface UserGoal {
	/** Geeft aan of het doel wordt bijgehouden */
	tracking?: boolean;
	/**
	 * Datum waarop het doel voor het laatst is gewijzigd
	 *
	 * @example
	 * 	01-05-2023
	 *
	 * @format date
	 */
	changed?: string;
	/** Fitnessniveau van het lid (1-10) */
	fitness?: number;
	/** Activiteitsniveau van het lid */
	activity?: "actief" | "passief";
	/** Tekstuele beschrijving van het doel */
	goalText?: string;
	/** Motivatie achter het doel (de waarom) */
	goal_rationale?: string;
	/** Persoonlijke waarden gerelateerd aan het doel (waar hecht een sporter waarde aan) */
	goal_personal_values?: string;
	doel?: {
		/** Type doel (bijv. gewicht, vetpercentage) */
		slug?: string;
		/** Doelwaarde */
		amount?: number;
	};
	achieve?: {
		cardio?: boolean;
		kracht?: boolean;
		groepslessen?: boolean;
		afvallenInAchtWeken?: boolean;
		personaltrainer?: boolean;
		sportvasten?: boolean;
		spierballenInAchtWeken?: boolean;
	};
	/** Hoe het lid door ProFit Gym geholpen kan worden om het doel te bereiken */
	helpAchieve?: string;
	/** Tijdsbestek waarin het lid het doel wil bereiken */
	timeAchieve?: string;
	/** Aantal keer per week dat het lid wil sporten */
	frequency?: number;
	/** Aantal minuten per keer dat het lid wil sporten */
	duration?: number;
	/** Sportgeschiedenis / sport ervaring van het lid */
	sports_history?: string;
}

export interface UserGoalInput {
	/**
	 * Datum waarop het doel voor is gewijzigd, moet de huidige datum zijn
	 *
	 * @example
	 * 	01-05-2023
	 *
	 * @format date
	 */
	changed?: string;
	/** Fitnessniveau van het lid (1-10) */
	fitness?: number;
	/** Activiteitsniveau van het lid */
	activity?: "actief" | "passief";
	/** Tekstuele beschrijving van het doel */
	goalText?: string;
	/** Motivatie achter het doel (de waarom) */
	goal_rationale?: string;
	/** Persoonlijke waarden gerelateerd aan het doel (waar hecht een sporter waarde aan) */
	goal_personal_values?: string;
	doel?: {
		/** Type doel (bijv. gewicht, vetpercentage) */
		slug?: string;
		/** Doelwaarde */
		amount?: number;
	};
	achieve?: {
		cardio?: boolean;
		kracht?: boolean;
		groepslessen?: boolean;
		afvallenInAchtWeken?: boolean;
		personaltrainer?: boolean;
		sportvasten?: boolean;
		spierballenInAchtWeken?: boolean;
	};
	/** Hoe het lid door ProFit Gym geholpen kan worden om het doel te bereiken */
	helpAchieve?: string;
	/** Tijdsbestek waarin het lid het doel wil bereiken */
	timeAchieve?: string;
	/** Aantal keer per week dat het lid wil sporten */
	frequency?: number;
	/** Aantal minuten per keer dat het lid wil sporten */
	duration?: number;
	/** Sportgeschiedenis / sport ervaring van het lid */
	sports_history?: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** Set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** Request path */
	path: string;
	/** Content type of request body */
	type?: ContentType;
	/** Query params */
	query?: QueryParamsType;
	/** Format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** Request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @version 2.0
 * @title Coach app API calls
 */
export class CoachApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	api = {
		/**
		 * No description
		 *
		 * @name TrainerFotoDetail
		 * @summary Haal foto van trainer op
		 * @request GET:/api/trainer/{trainerId}/foto
		 */
		trainerFotoDetail: (trainerId: number, params: RequestParams = {}) =>
			this.request<
				{
					/**
					 * @example
					 * 	https://www.leukefoto.com
					 */
					trainer_foto_url?: string;
				},
				void
			>({
				path: `/api/trainer/${trainerId}/foto`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UserReserveringenDetail
		 * @summary Haal gereserveerde lessen voor een lid voor de komende 4 weken op.
		 * @request GET:/api/user/{id}/reserveringen
		 */
		userReserveringenDetail: (id: number, params: RequestParams = {}) =>
			this.request<GuessedReservering[], any>({
				path: `/api/user/${id}/reserveringen`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenActueelList
		 * @summary Haal de lessen op die plaatsvinden in de afgelopen 7 dagen en komende 7 dagen.
		 * @request GET:/api/lessen/actueel
		 */
		lessenActueelList: (params: RequestParams = {}) =>
			this.request<LesUitgebreid[], any>({
				path: `/api/lessen/actueel`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenWeekYearCreate
		 * @summary Haal lessen op voor een week+jaar gefilterd op vestiging en groepslesId.
		 * @request POST:/api/lessen/week/{week}/year/{year}
		 */
		lessenWeekYearCreate: (
			week: number,
			year: number,
			filterInformatie: {
				/**
				 * Id van de groepsles
				 *
				 * @format number
				 */
				groepsles?: number;
				/**
				 * @example
				 * 	zwolle - zuid;
				 */
				vestiging_naam?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<LesUitgebreid[], any>({
				path: `/api/lessen/week/${week}/year/${year}`,
				method: "POST",
				body: filterInformatie,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenAankomendCreate
		 * @summary Haal de aankomende lessen (voor 2 weken) op voor de huidige veisting
		 * @request POST:/api/lessen/aankomend
		 */
		lessenAankomendCreate: (params: RequestParams = {}) =>
			this.request<LesUitgebreid[], any>({
				path: `/api/lessen/aankomend`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LoginCreate
		 * @summary Inloggen op de coach app; geeft toegang tot de andere API calls.
		 * @request POST:/api/login
		 */
		loginCreate: (usernamePassword: LoginUsernamePassword, params: RequestParams = {}) =>
			this.request<OauthToken, OauthError>({
				path: `/api/login`,
				method: "POST",
				body: usernamePassword,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LoginRefreshCreate
		 * @summary Refresh de huidige tokens.
		 * @request POST:/api/login/refresh
		 */
		loginRefreshCreate: (refresh: LoginRefresh, params: RequestParams = {}) =>
			this.request<OauthToken, OauthError>({
				path: `/api/login/refresh`,
				method: "POST",
				body: refresh,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LoginMemberCreate
		 * @summary Inloggen op de leden-portal API; geeft toegang tot de andere API leden-portal API calls.
		 * @request POST:/api/login/member
		 */
		loginMemberCreate: (usernamePassword: LoginUsernamePassword, params: RequestParams = {}) =>
			this.request<OauthToken, OauthError>({
				path: `/api/login/member`,
				method: "POST",
				body: usernamePassword,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LoginRefreshMemberCreate
		 * @summary Refresh de huidige tokens.
		 * @request POST:/api/login/refresh/member
		 */
		loginRefreshMemberCreate: (refresh: LoginRefresh, params: RequestParams = {}) =>
			this.request<OauthToken, OauthError>({
				path: `/api/login/refresh/member`,
				method: "POST",
				body: refresh,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenAanwezigheidslijstDetail
		 * @summary Haal aanwezigheidslijst op voor een les.
		 * @request GET:/api/lessen/{lesId}/aanwezigheidslijst
		 */
		lessenAanwezigheidslijstDetail: (lesId: number, params: RequestParams = {}) =>
			this.request<AanwezigheidsLijstExtern, void>({
				path: `/api/lessen/${lesId}/aanwezigheidslijst`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenAanwezigheidslijstCreate
		 * @summary Wijzig de aanwezigheidslijst voor een les. Use cases:
		 * (1) registreren wie aan/afwezig was bij de les
		 * (2) reserveren van een enkele les (evt herhalend), in dit geval een lijst met maar 1 aanwezigheid meesturen
		 * (3) afmelden van een enkele les, in dit geval een lijst met maar 1 aanwezigheid meesturen
		 * @request POST:/api/lessen/aanwezigheidslijst
		 */
		lessenAanwezigheidslijstCreate: (
			aanwezigheidslijst: AanwezigheidsLijstExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/api/lessen/aanwezigheidslijst`,
				method: "POST",
				body: aanwezigheidslijst,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenAanwezigheidslijstDefinitiefCreate
		 * @summary Maak de aanwezigheidslijst voor een les definitief.
		 * @request POST:/api/lessen/{lesId}/aanwezigheidslijst/definitief
		 */
		lessenAanwezigheidslijstDefinitiefCreate: (lesId: number, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/api/lessen/${lesId}/aanwezigheidslijst/definitief`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name FitnessafsprakenAanwezigheidCreate
		 * @summary Reserveer/annuleer reservering voor een fitness afspraak of update de boeking voor een afspraak (meting, doelOpstellen, schema booleans).
		 * @request POST:/api/fitnessafspraken/{afspraakId}/aanwezigheid
		 */
		fitnessafsprakenAanwezigheidCreate: (
			afspraakId: number,
			afspraakAanwezigheid: AanwezigheidFitnessAfspraakExtern,
			params: RequestParams = {},
		) =>
			this.request<FitnessAfspraakExtern, void>({
				path: `/api/fitnessafspraken/${afspraakId}/aanwezigheid`,
				method: "POST",
				body: afspraakAanwezigheid,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name AppointmentsBookedRangeCreate
		 * @summary Haal alle gereserveerde afspraken voor een locatie op die vallen binnen de meegegeven range (inclusief begin en eindpunt). Informatie over wie er zich heeft aangemeld wordt ook meegestuurd.
		 * @request POST:/api/appointments-booked/range/{locationName}
		 */
		appointmentsBookedRangeCreate: (
			locationName: string,
			datumRange: DatumRange,
			params: RequestParams = {},
		) =>
			this.request<FitnessAfspraakExtern[], void>({
				path: `/api/appointments-booked/range/${locationName}`,
				method: "POST",
				body: datumRange,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name AppointmentsBookedMemberCreate
		 * @summary Haal alle gereserveerde afspraken voor een locatie op die vallen binnen de meegegeven range (inclusief begin en eindpunt). Informatie over wie er zich heeft aangemeld wordt ook meegestuurd.
		 * @request POST:/api/appointments-booked/member/{id}
		 */
		appointmentsBookedMemberCreate: (
			id: number,
			datumRange: DatumRange,
			params: RequestParams = {},
		) =>
			this.request<FitnessAfspraakExtern[], void>({
				path: `/api/appointments-booked/member/${id}`,
				method: "POST",
				body: datumRange,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name AppointmentsMonthDetail
		 * @summary Haal de afspraken opties op voor een maand. Per dag worden de mogelijke slots doorgegeven, met een boolean of ze al bezet zijn.
		 * @request GET:/api/appointments-month/{locationName}/{date}
		 */
		appointmentsMonthDetail: (locationName: string, date: string, params: RequestParams = {}) =>
			this.request<AfsprakenMaand, any>({
				path: `/api/appointments-month/${locationName}/${date}`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name AppointmentsDoneCreate
		 * @summary markeert de meegegeven afspraken als uitgevoerd door de coach
		 * @request POST:/api/appointments/{appointmentId}/done
		 */
		appointmentsDoneCreate: (
			appointmentId: number,
			aanwezigheid: AanwezigheidExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/api/appointments/${appointmentId}/done`,
				method: "POST",
				body: aanwezigheid,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UserGroepslessenDetail
		 * @summary Haal de laatst gevolgde groepslessen (+ algemene info) op voor de gebruiker. Geeft ook suggesties voor eventueel te volgen lessen.
		 * Voor het ophalen van de eerste volgende lessen voor een suggestie gebruikt de call: `/api/lessen/week/{week}/year/{year}`.
		 * @request GET:/api/user/{id}/groepslessen
		 */
		userGroepslessenDetail: (id: number, params: RequestParams = {}) =>
			this.request<GroepslessenResultaat, any>({
				path: `/api/user/${id}/groepslessen`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name GroepslessenDetail
		 * @summary Haal de details op voor een enkele groepsles
		 * @request GET:/api/groepslessen/{id}
		 */
		groepslessenDetail: (id: number, params: RequestParams = {}) =>
			this.request<GroepslesResultaat, any>({
				path: `/api/groepslessen/${id}`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenAnnulerenCreate
		 * @summary annuleer een les met een optionele reden
		 * @request POST:/api/lessen/annuleren
		 */
		lessenAnnulerenCreate: (data: AnnuleerData, params: RequestParams = {}) =>
			this.request<void, void>({
				path: `/api/lessen/annuleren`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LessenDetail
		 * @summary les ophalen voor gegeven `id`
		 * @request GET:/api/lessen/{id}
		 */
		lessenDetail: (id: number, params: RequestParams = {}) =>
			this.request<LesUitgebreid, any>({
				path: `/api/lessen/${id}`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TrainersList
		 * @summary alle trainers ophalen
		 * @request GET:/api/trainers
		 */
		trainersList: (params: RequestParams = {}) =>
			this.request<TrainerResultaat[], any>({
				path: `/api/trainers`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name LoginInfoList
		 * @summary info van de ingelogde gebruiker ophalen
		 * @request GET:/api/login/info
		 */
		loginInfoList: (params: RequestParams = {}) =>
			this.request<IngelogdeGebruiker, any>({
				path: `/api/login/info`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UserProfileImageDetail
		 * @summary user profile afbeelding ophalen
		 * @request GET:/api/user/{id}/profile-image
		 */
		userProfileImageDetail: (id: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/user/${id}/profile-image`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TrainerProfileImageDetail
		 * @summary trainer foto ophalen
		 * @request GET:/api/trainer/{id}/profile-image
		 */
		trainerProfileImageDetail: (id: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/trainer/${id}/profile-image`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name IntakeCreate
		 * @summary nieuwe intake toevoegen
		 * @request POST:/api/intake
		 */
		intakeCreate: (model: Intake, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/intake`,
				method: "POST",
				body: model,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name WelkomCreate
		 * @summary nieuw welkomst formulier toevoegen
		 * @request POST:/api/welkom
		 */
		welkomCreate: (model: Welkom, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/api/welkom`,
				method: "POST",
				body: model,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TijdslotenActueelList
		 * @summary Haal de tijdsloten op die plaatsvinden in de afgelopen 7 dagen en komende 7 dagen.
		 * @request GET:/api/tijdsloten/actueel
		 */
		tijdslotenActueelList: (params: RequestParams = {}) =>
			this.request<Tijdslot[], any>({
				path: `/api/tijdsloten/actueel`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TijdslotenDetail
		 * @summary tijdslot ophalen voor gegeven `id`
		 * @request GET:/api/tijdsloten/{id}
		 */
		tijdslotenDetail: (id: number, params: RequestParams = {}) =>
			this.request<LesUitgebreid, any>({
				path: `/api/tijdsloten/${id}`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TijdslotenAanwezigheidslijstDetail
		 * @summary Haal aanwezigheidslijst op voor een tijdslot.
		 * @request GET:/api/tijdsloten/{tijdslotId}/aanwezigheidslijst
		 */
		tijdslotenAanwezigheidslijstDetail: (tijdslotId: number, params: RequestParams = {}) =>
			this.request<AanwezigheidsLijstExtern, void>({
				path: `/api/tijdsloten/${tijdslotId}/aanwezigheidslijst`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TijdslotenAanwezigheidslijstCreate
		 * @summary Wijzig de aanwezigheidslijst voor een tijdslot. Herhalend aanmelden voor een tijdslot is niet mogelijk.
		 * @request POST:/api/tijdsloten/aanwezigheidslijst
		 */
		tijdslotenAanwezigheidslijstCreate: (
			aanwezigheidslijst: AanwezigheidsLijstExtern,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/api/tijdsloten/aanwezigheidslijst`,
				method: "POST",
				body: aanwezigheidslijst,
				...params,
			}),

		/**
		 * No description
		 *
		 * @name TijdslotenAanwezigheidslijstDefinitiefCreate
		 * @summary Maak de aanwezigheidslijst voor een tijdslot definitief.
		 * @request POST:/api/tijdsloten/{tijdslotId}/aanwezigheidslijst/definitief
		 */
		tijdslotenAanwezigheidslijstDefinitiefCreate: (
			tijdslotId: number,
			params: RequestParams = {},
		) =>
			this.request<void, void>({
				path: `/api/tijdsloten/${tijdslotId}/aanwezigheidslijst/definitief`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UserGoalDetail
		 * @summary Haal het huidige doel en gerelateerde informatie op voor een lid
		 * @request GET:/api/user/{id}/goal
		 */
		userGoalDetail: (id: number, params: RequestParams = {}) =>
			this.request<UserGoal, any>({
				path: `/api/user/${id}/goal`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name UserGoalCreate
		 * @summary Werk het doel voor een lid bij
		 * @request POST:/api/user/{id}/goal
		 */
		userGoalCreate: (id: number, goalData: UserGoalInput, params: RequestParams = {}) =>
			this.request<
				{
					/**
					 * @example
					 * 	saved;
					 */
					data?: string;
				},
				any
			>({
				path: `/api/user/${id}/goal`,
				method: "POST",
				body: goalData,
				type: ContentType.Json,
				...params,
			}),
	};
}
